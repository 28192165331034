<template>
    <v-row justify="center">
         <v-dialog value="1" fullscreen  eager transition="dialog-bottom-transition">
             <v-card class="card" flat :style="{ 'background': $vuetify.theme.themes.light.background }">
                 <section class="d-flex justify-center">
                     <v-container class="px-1 px-1">
                         <div class="d-flex justify-center pt-15 mt-16">
                            <v-img max-width="288" :src="logoURI"></v-img>
                         </div>
                         <h1 class="card-title mt-15 mb-15">{{translate('would_you_like_to_add','Would you like to add these?')}}</h1>
                         <div class="d-flex flex-row justify-center" v-if="!upsellItems.length">
                           <v-progress-circular
                               indeterminate
                               :size="100"
                               color="primary"
                           ></v-progress-circular>
                         </div>
                         <v-item-group multiple v-model="selected" active-class="card-selected" class="items" v-else>
                            <CartItem v-for="(item, index) in upsellItems"
                                :del-button="false"
                                :upsell="true"
                                @input="changeQty"
                                :item="item"
                                :bus="bus"
                                :index="index"
                                :key="index"
                                @select="addtocart"/>
                         </v-item-group>

                     </v-container>
                 </section>
                <BaseFooter @next-step="$router.push({name:'menu.payment'})" />
             </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { Menu } from '@/services/SOLO'
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import BaseFooter from '@/components/base/BaseFooter.vue';
import CartItem from '@/components/cart/CartItem.vue';
import Vue from 'vue';

export default {
    name: 'Upsell',
    components:{
        BaseFooter,
        CartItem
    },
    mixins: [itemMixin, translationMixin],
    data:()=>({
        selected: [],
        upsells: [],
        upsellItems: [],
        bus: new Vue(),
    }),
    computed:{
        ...mapGetters({
            items: 'cart/items',
            getConcept: 'concept/getConcept',
            getApplications: 'concept/getApplications',
            locale: 'app/getLocale',
        }),
        logoURI() {
            return this.getConcept['logo-uri'];
        },
    },
    mounted() {
      if (this.getApplications['default-menu-id'] || this.getConcept['default-menu-id']) {
        this.getUpsells(this.getApplications['default-menu-id'] || this.getConcept['default-menu-id']);
      }
    },
    methods: {
        ...mapMutations({
            add: 'cart/add',
            updateQuantity: 'cart/updateQuantity',
            remove: 'cart/remove',
        }),
        changeQty({index, quantity}) {
            let upsellItem = this.upsells[index]
            this.items.map((item, i) => {
                if(item.id === upsellItem.id) {
                    this.updateQuantity({index: i, quantity: quantity})
                    this.upsellItems[index].quantity = quantity
                }
            })
        },
        getUpsells(menuId) {
            Menu.upsells(menuId, this.locale).then(response => {
                this.upsells = response.data.data
                this.upsellItems = this.upsells.map(upsellItem => {

                    let qty = 1
                    let filteredItems = this.items.filter(item => { return item.id == upsellItem.id})

                    if(filteredItems.length) {
                        qty = filteredItems[0].quantity
                    }

                    return {
                        name: upsellItem.attributes.name,
                        modifiers: [],
                        imgUrl: upsellItem.attributes['image-uri'],
                        total: upsellItem.attributes.price,
                        quantity: qty,
                        selected:  filteredItems.length ? true : false
                    }
                })
            }).catch(err => {
                console.log(err.response)
            })
        },
        addtocart({index, selected}) {
            if(selected) {
                var item = {
                    id: this.upsells[index].id,
                    price: this.upsells[index].attributes.price,
                    description: this.upsells[index].attributes.description,
                    quantity: 1,
                    notes: "notes",
                    name: this.upsells[index].attributes.name,
                    imgUrl: this.upsells[index].attributes['image-uri'],
                    discount: 0,
                    'combo-items': [],
                    modifiers: [],
                    ingredients: [],
                    total: this.upsells[index].attributes.price
                }
                this.add(item)
            } else {
                let upsellItem = this.upsells[index]
                this.items.map((item, i) => {
                    if(item.id == upsellItem.id) {
                        this.remove(i)
                    }
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.card{
    &-selected{
         border: 3px solid var(--v-primary-base);
    }
    .items{
        margin-bottom: 300px !important;
    }
    section{
        .container{
            max-width: 1000px !important;
        }
    }
    &-title{
        font-weight: 600 !important;
        font-size: 48px !important;
        text-align: center;
        color: #000;
    }
    &-description{
        font-weight: normal !important;
        font-size: 36px !important;
        line-height: 100%;
        text-align: center;
        color: #2F2F2F
    }
}
</style>
