<template>
<v-item v-slot="{toggle}">
    <v-card class="card shadow px-3 mb-10" :class="{ 'selected': selected }" flat @click="selectItem(toggle)" >
        <v-row>
            <v-col cols="4">
                <v-img :src="item.imgUrl" />
            </v-col>
            <v-col cols="8">
                <v-btn v-if="delButton" @click.stop="$emit('remove-item', index)"  width="64" height="64" color="grey" absolute fab depressed  :class="{ 'del-btn-ar': locale === 'ar-sa' }" class="del-btn mx-2 mt-2">
                    <v-icon color="white" size="35">mdi-close</v-icon>
                </v-btn>
                <section class="card-content">
                    <h2 class="card-title mt-8 mb-5" :class="{ 'card-title--ar': locale === 'ar-sa' }">{{item.name | name}}</h2>
                    <p class="card-description my-3">{{item.modifiers | modifiers}}</p>
                    <section class="d-flex mt-15 align-center">
                        <div class="card-price">{{ translateNumber(item.total * quantity) }} {{ translate(currency.key, currency.value) }}</div>
                        <QuantityOption
                            class="card-quantity mx-4"
                            v-if="(upsell && selected) || !upsell"
                            v-model="quantity"
                            :minusColor="$vuetify.theme.themes.light.primary"
                            :plusColor="$vuetify.theme.themes.light.primary"
                            :strict="false"
                            :size="64" />
                    </section>
                </section>
            </v-col>
        </v-row>
    </v-card>
</v-item>
</template>
<script>
import Vue from 'vue';
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import QuantityOption from '@/components/base/QuantityOption.vue';
import { mapGetters } from "vuex";
export default {
    name:'CartItem',
    components:{
        QuantityOption
    },
    mixins: [itemMixin, translationMixin],
    props:{
        upsell: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number
        },
        item:{
            required: true,
            default:()=>Object
        },
        delButton:{
            type: Boolean,
            default: true
        },
        bus: {
            type: Vue
        },
        delButton:{
            type: Boolean,
            default: true
        }
    },

    computed:{
      ...mapGetters({
        locale: 'app/getLocale',
        currency: 'concept/currency',
      }),
        quantity: {
            get() {
                return this.item.quantity
            },
            set(v) {
                this.$emit('input', {index: this.index, quantity: v})
            }
        },
    },
    mounted() {
        if(this.upsell) {
            this.selected = this.item.selected
        }
    },
    data:()=>({
        selected: false
    }),
    methods: {
        selectItem(toggle) {
            if(this.upsell) {
                toggle()
                this.selected = this.selected ? false : true
                this.$emit('select', {index: this.index, selected: this.selected})
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.del-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  &-ar {
    right: unset;
    left: 16px;
  }
}
.active{
background: #FFFFFF;
border: 3px solid #2B388F !important;

box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
border-radius: 20px !important;
}
.card{
    background: #FFFFFF;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(209, 148, 148, 0.04) !important;
    border-radius: 20px !important;
    &-quantity{
        width: 35%;
    }
    &-price{
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        color: var(--v-primary-base);
    }
    &-description{
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #000000;
    }
    &-title{
      font-weight: bold;
      font-size: 36px;
      color: #000000;
      padding-right: 88px;
      &--ar {
        padding-right: 0;
        padding-left: 88px;
      }
    }
}

</style>
